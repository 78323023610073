import { compose } from '@blendle/recompose';
import { string, func, oneOf } from 'prop-types';
import React, { useEffect } from 'react';
import R from 'ramda';
import withFirstName from 'higher-order-components/withFirstName';
import {
  STATUS_OK,
  PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL,
  FEATURE_CAPABILITY_MICROPAYMENTS,
} from 'app-constants';
import altConnect from 'higher-order-components/altConnect';
import SubscriptionsActions from 'actions/SubscriptionsActions';
import SubscriptionsStore from 'stores/SubscriptionsStore';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import useUser from 'hooks/useUser';
import useEventOnMount from 'hooks/useEventOnMount';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import Loading from '../components/Loading';
import RecapturePageComponent, {
  GIFT_VERSION,
  CONTINUE_READING_VERSION,
} from '../components/RecapturePage';

const RecapturePageContainer = ({ firstName, version, fetchUserSubscriptions }) => {
  const user = useUser();

  const properties = {
    internal_location: 'blendle-premium-upsell-screen',
    tags: [version],
  };

  useEventOnMount('Blendle Premium Upsell Screen Shown', properties);
  usePageEventOnMount({
    pageName: 'Blendle Premium Upsell Screen',
    properties,
  });

  useEffect(() => {
    fetchUserSubscriptions(user.id, { active: false });
  }, []);

  return version ? <RecapturePageComponent firstName={firstName} version={version} /> : <Loading />;
};

RecapturePageContainer.propTypes = {
  firstName: string,
  fetchUserSubscriptions: func.isRequired,
  version: oneOf([GIFT_VERSION, CONTINUE_READING_VERSION]),
};

RecapturePageContainer.defaultProps = {
  firstName: undefined,
  version: undefined,
};

const isReady = R.equals(STATUS_OK);
const isEligibleFor30DayTrial = R.compose(
  R.isNil,
  R.find(R.propEq('uid', PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL)),
);

function mapStateToProps({ subscriptionsState, featureCapabilitiesState }) {
  const { status, subscriptions } = subscriptionsState;

  if (isReady(status)) {
    const userHasMicropayments = hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_MICROPAYMENTS,
    );

    if (userHasMicropayments || !isEligibleFor30DayTrial(subscriptions)) {
      return {
        version: CONTINUE_READING_VERSION,
      };
    }

    return {
      version: GIFT_VERSION,
    };
  }

  return {};
}

mapStateToProps.stores = {
  SubscriptionsStore,
  FeatureCapabilitiesStore,
};

export default compose(
  altConnect(mapStateToProps, {
    fetchUserSubscriptions: SubscriptionsActions.fetchUserSubscriptions.defer,
  }),
  withFirstName,
)(RecapturePageContainer);
