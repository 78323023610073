// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uspBody-3R76Q{font-family:\"GT-Walsheim\",sans-serif;color:#5b5b5b;font-size:16px;line-height:23px}\n", ""]);
// Exports
exports.locals = {
	"uspBody": "uspBody-3R76Q"
};
module.exports = exports;
