// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1v6jN{display:flex;flex-wrap:wrap;justify-content:left;margin:0 auto 60px}@media (min-width: 600px){.container-1v6jN{justify-content:center;margin:0 auto 110px}}.benefitPair-BiymC{display:flex;flex-wrap:wrap}.benefit-1Yldd{margin:8px 12px 0;display:flex;align-items:center;min-width:160px}.body-2j_8l{margin-left:9px;color:#5b5b5b;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:15px;letter-spacing:-.32px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1v6jN",
	"benefitPair": "benefitPair-BiymC",
	"benefit": "benefit-1Yldd",
	"body": "body-2j_8l"
};
module.exports = exports;
