// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3_1TV{height:100%;display:flex;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3_1TV"
};
module.exports = exports;
