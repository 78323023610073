// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2XkBv{margin-top:32px;display:flex;flex-wrap:wrap;justify-content:left}@media (min-width: 600px){.container-2XkBv{margin-top:56px;justify-content:space-evenly}}.iconContainer-DLnDr{display:block;height:20px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2XkBv",
	"iconContainer": "iconContainer-DLnDr"
};
module.exports = exports;
