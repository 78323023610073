// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-fnNDM{display:flex;justify-content:center;flex-wrap:wrap}\n", ""]);
// Exports
exports.locals = {
	"container": "container-fnNDM"
};
module.exports = exports;
