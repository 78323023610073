// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-3zH16{max-width:322px;margin:0 auto;display:flex;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-3zH16"
};
module.exports = exports;
