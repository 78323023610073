import altConnect from 'higher-order-components/altConnect';
import AuthStore from 'stores/AuthStore';
import { getFirstName } from 'helpers/user';

const mapStateToProps = ({ authState }) => ({
  firstName: getFirstName(authState),
});

mapStateToProps.stores = { AuthStore };

export default altConnect(mapStateToProps);
