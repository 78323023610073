import React from 'react';
import styledModule from 'helpers/styledModule';
import {
  CompassIcon,
  EnvelopeIcon,
  FunnelIcon,
  HeadphonesIcon,
  StarIcon,
  StopIcon,
  UserIcon,
} from '@blendle/lego';
import { FEATURE_CAPABILITY_MICROPAYMENTS } from 'app-constants';
import CapabilityIsLocked from 'components/CapabilityIsLocked';
import SectionHeader from '../SectionHeader';
import USP from '../USP';
import USPTitle from '../USPTitle';
import USPBody from '../USPBody';
import CSS from './WhyPremium.scss';

const Container = styledModule.div(CSS.container);
const IconContainer = styledModule.div(CSS.iconContainer);

function renderRecapture() {
  return (
    <USP>
      <IconContainer>
        <UserIcon />
      </IconContainer>
      <USPTitle>Alles wat je ziet, is van jou</USPTitle>
      <USPBody>
        Je dagelijkse dosis journalistiek. Kom lekker naar hartenlust rondsnoepen in je selectie.
      </USPBody>
    </USP>
  );
}

function renderUpsell() {
  return (
    <USP>
      <IconContainer>
        <CompassIcon />
      </IconContainer>
      <USPTitle>Ga op ontdekkingsreis</USPTitle>
      <USPBody>
        Laat je door ons verrassen. Ontdek artikelen en onderwerpen die je anders had gemist.
      </USPBody>
    </USP>
  );
}

function WhyPremium() {
  return (
    <div>
      <SectionHeader
        title="Waarom Premium?"
        subtitle="Met Blendle Premium krijg je een fantastisch leven (zolang de voorraad strekt)"
      />
      <Container>
        <USP>
          <IconContainer>
            <EnvelopeIcon />
          </IconContainer>
          <USPTitle>Blendle Krantenoverzicht</USPTitle>
          <USPBody>
            Iedere ochtend de grootste journalistieke nieuwsbrief van Nederland in je inbox.
          </USPBody>
        </USP>
        <USP>
          <IconContainer>
            <HeadphonesIcon />
          </IconContainer>
          <USPTitle>Blendle Audio</USPTitle>
          <USPBody>
            De belangrijkste stukken worden iedere dag voorgelezen door professionele stemmen.
          </USPBody>
        </USP>
        <CapabilityIsLocked capability={FEATURE_CAPABILITY_MICROPAYMENTS}>
          {({ capabilityIsLocked }) => (capabilityIsLocked ? renderRecapture() : renderUpsell())}
        </CapabilityIsLocked>
        <USP>
          <IconContainer>
            <StarIcon />
          </IconContainer>
          <USPTitle>Het beste uit binnen- en buitenland</USPTitle>
          <USPBody>
            Van de Volkskrant tot The New York Times, van LINDA tot TIME. Kortom, een breed scala.
          </USPBody>
        </USP>
        <USP>
          <IconContainer>
            <FunnelIcon />
          </IconContainer>
          <USPTitle>Persoonlijk voor jou geselecteerd</USPTitle>
          <USPBody>
            Wij lezen alles, dan hoef jij het niet te doen. Dat scheelt tijd. Je krijgt wat écht
            goed is.
          </USPBody>
        </USP>
        <USP>
          <IconContainer>
            <StopIcon />
          </IconContainer>
          <USPTitle>Zonder advertenties, natuurlijk</USPTitle>
          <USPBody>
            Geen banners, pop-ups en andere fratsen. Krijgt je adblocker ook een momentje rust.
          </USPBody>
        </USP>
      </Container>
    </div>
  );
}

export default WhyPremium;
