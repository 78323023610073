import React from 'react';
import { oneOf } from 'prop-types';
import { equals, ifElse } from 'ramda';
import styledModule from 'helpers/styledModule';
import { PREMIUM_YEARLY_PRODUCT, PREMIUM_MONTHLY_PRODUCT } from 'app-constants';
import CallToActionButton from '../../CallToActionButton';
import CSS from './Subscriptions.scss';

const Card = styledModule.div(CSS.card);
const Discount = styledModule.div(CSS.discount);
const Title = styledModule.h3(CSS.title);
const PriceContainer = styledModule.div(CSS.priceContainer);
const Price = styledModule.p(CSS.price);
const Schedule = styledModule.p(CSS.body, CSS.schedule);
const Body = styledModule.p(CSS.body);

const cardValues = {
  yearly: {
    isDiscount: true,
    title: 'Jaarabonnement',
    price: '8,25',
    body: 'Neem 1 jaar Blendle Premium en krijg 2 maanden gratis. Je rekent in één klap € 99 af.',
    buttonColor: 'razzmatazz',
    borderCorrection: false,
  },
  monthly: {
    isDiscount: false,
    title: 'Maandabonnement',
    price: '9,99',
    body: 'Blijf lekker flexibel en reken per maand af. Je kunt op ieder moment online opzeggen.',
    buttonColor: 'razzmatazz-outline',
    borderCorrection: true,
  },
};

const isYearlySubscription = equals(PREMIUM_YEARLY_PRODUCT);
const getCardValuesFromSubscriptionType = ifElse(
  isYearlySubscription,
  () => cardValues.yearly,
  () => cardValues.monthly,
);

function Subscription({ subscriptionType }) {
  const {
    isDiscount,
    title,
    price,
    body,
    buttonColor,
    borderCorrection,
  } = getCardValuesFromSubscriptionType(subscriptionType);

  return (
    <Card>
      {isDiscount && <Discount>Aanbevolen - bespaar 17%</Discount>}
      <Title>{title}</Title>
      <PriceContainer>
        <Price>€{price}</Price>
        <Schedule>per maand</Schedule>
      </PriceContainer>
      <Body>{body}</Body>
      <CallToActionButton
        subscriptionType={subscriptionType}
        buttonColor={buttonColor}
        borderCorrection={borderCorrection}
        locationInLayout="header"
      >
        Neem Premium
      </CallToActionButton>
    </Card>
  );
}

Subscription.propTypes = {
  subscriptionType: oneOf([PREMIUM_YEARLY_PRODUCT, PREMIUM_MONTHLY_PRODUCT]).isRequired,
};

export default Subscription;
