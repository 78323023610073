import React from 'react';
import styledModule from 'helpers/styledModule';
import { PREMIUM_YEARLY_PRODUCT } from 'app-constants';
import CallToActionButton from '../../CallToActionButton';
import CSS from './BottomCallToActionSection.scss';

const Wrapper = styledModule.div(CSS.wrapper);

function BottomCallToActionSection() {
  return (
    <Wrapper>
      <CallToActionButton subscriptionType={PREMIUM_YEARLY_PRODUCT} locationInLayout="footer" />
    </Wrapper>
  );
}

export default BottomCallToActionSection;
