// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aligment-2kLRe{max-width:1024px;margin:0 auto;padding:0 20px}@media (min-width: 1064px){.aligment-2kLRe{padding:0}}\n", ""]);
// Exports
exports.locals = {
	"aligment": "aligment-2kLRe"
};
module.exports = exports;
