// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".usp-14p4u{text-align:left;width:318px;min-height:130px;margin:0 11px 25px 11px}@media (min-width: 600px){.usp-14p4u{margin-bottom:50px}}\n", ""]);
// Exports
exports.locals = {
	"usp": "usp-14p4u"
};
module.exports = exports;
