import React from 'react';
import { string, oneOf } from 'prop-types';
import ContinueReading from './ContinueReading';
import Gift from './Gift';

export const GIFT_VERSION = 'GIFT_VERSION';
export const CONTINUE_READING_VERSION = 'CONTINUE_READING_VERSION';

const RecapturePageComponent = ({ firstName, version }) => {
  switch (version) {
    case GIFT_VERSION:
      return <Gift firstName={firstName} />;
    default:
      return <ContinueReading firstName={firstName} />;
  }
};

RecapturePageComponent.propTypes = {
  firstName: string,
  version: oneOf([GIFT_VERSION, CONTINUE_READING_VERSION]).isRequired,
};

RecapturePageComponent.defaultProps = {
  firstName: undefined,
};

export default RecapturePageComponent;
