import Link from 'components/Link';
import PrivacyLink from 'containers/PrivacyLink';
import styledModule from 'helpers/styledModule';
import React from 'react';
import CSS from './Footer.scss';

const Container = styledModule.div(CSS.container);
const FooterLink = styledModule(Link)(CSS.link);
const FooterPrivacyLink = styledModule(PrivacyLink)(CSS.link);

function Footer() {
  return (
    <Container>
      <FooterPrivacyLink>Privacy</FooterPrivacyLink>
      <FooterLink href="/about/termsandconditions">Algemene voorwaarden</FooterLink>
      <FooterLink href="https://www.blendle.support" target="_blank">
        Veelgestelde vragen
      </FooterLink>
    </Container>
  );
}

export default Footer;
