import React from 'react';
import { string } from 'prop-types';
import { FEATURE_CAPABILITY_MICROPAYMENTS } from 'app-constants';
import CapabilityIsLocked from 'components/CapabilityIsLocked';
import CSS from './Intro.scss';

function renderRecapture(firstName) {
  return (
    <div>
      <p className={CSS.greeting}>
        {firstName ? `Hoi ${firstName}, leuk dat je terug bent!` : 'Leuk dat je terug bent!'}
      </p>
      <h1 className={CSS.title}>Neem nu Premium en ga door met lezen</h1>
    </div>
  );
}

function renderUpsell(firstName) {
  return (
    <div>
      <p className={CSS.greeting}>
        {['Hoi', firstName].filter(Boolean).join(' ')}, leuk je te zien!
      </p>
      <h1 className={CSS.title}>Neem Premium en haal het beste uit Blendle</h1>
    </div>
  );
}

const Intro = ({ firstName }) => (
  <CapabilityIsLocked capability={FEATURE_CAPABILITY_MICROPAYMENTS}>
    {({ capabilityIsLocked }) =>
      capabilityIsLocked ? renderRecapture(firstName) : renderUpsell(firstName)
    }
  </CapabilityIsLocked>
);

Intro.propTypes = {
  firstName: string,
};

Intro.defaultProps = {
  firstName: undefined,
};

export default Intro;
