// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button-3553W{width:100%;padding:12px 20px;margin-top:16px;text-align:center;font-size:16px;line-height:21px;letter-spacing:-.32px}@media (min-width: 600px){.button-3553W{padding:16px 20px;margin-top:56px}}.borderCorrection-1CqzL{padding:11px 20px}@media (min-width: 600px){.borderCorrection-1CqzL{padding:15px 20px}}\n", ""]);
// Exports
exports.locals = {
	"button": "button-3553W",
	"borderCorrection": "borderCorrection-1CqzL"
};
module.exports = exports;
