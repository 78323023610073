// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".card-Yg85b{display:flex;flex-direction:column;max-width:322px;min-height:255px;border:1px solid rgba(0,0,0,0.1);border-radius:4px;padding:20px 26px 26px 32px;margin:0 auto;text-align:left}.apostrophe-3rrvN{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-size:35px;line-height:35px;letter-spacing:-.7px;margin-bottom:-10px}.apostrophe-3rrvN:after{content:'\\201C'}.body-p6P9n{color:#333;font-family:\"GT-Walsheim\",sans-serif;line-height:25px;font-size:18px}.footer-3hCib{display:flex;align-items:center;margin-top:16px}.avatar-2puEA{width:32px;height:32px;margin-right:8px;border-radius:50%}.name-mo9q-{color:#707070;font-family:\"GT-Walsheim\",sans-serif;font-size:14px;line-height:18px;letter-spacing:-.28px}\n", ""]);
// Exports
exports.locals = {
	"card": "card-Yg85b",
	"apostrophe": "apostrophe-3rrvN",
	"body": "body-p6P9n",
	"footer": "footer-3hCib",
	"avatar": "avatar-2puEA",
	"name": "name-mo9q-"
};
module.exports = exports;
