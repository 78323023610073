// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".card-7oN3x{max-width:335px;min-height:311px;width:100%;height:100%;border:1px solid rgba(0,0,0,0.1);border-radius:4px;text-align:left;padding:32px;margin:0 0 18px;position:relative}@media (min-width: 600px){.card-7oN3x{max-width:410px;min-height:380px;padding:40px;margin:0 15px 24px}}.discount-RBIxN{height:21px;font-size:14px;line-height:20px;color:#fff;text-align:center;font-family:\"GT-Walsheim\",sans-serif;background-color:#ff1060;position:absolute;top:-1px;left:-1px;right:-1px;border-top-left-radius:4px;border-top-right-radius:4px}@media (min-width: 600px){.discount-RBIxN{height:26px;font-size:15px;line-height:21px;padding-top:1px}}.title-393ca{margin-bottom:8px;color:#333;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;font-size:20px;line-height:28px;letter-spacing:-.4px}@media (min-width: 600px){.title-393ca{margin-bottom:16px;font-size:24px;line-height:34px;letter-spacing:-1.2px}}.priceContainer-3O_ME{display:flex;margin-bottom:24px}@media (min-width: 600px){.priceContainer-3O_ME{margin-bottom:36px}}.price-3IdUj{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;font-size:34px;line-height:48px;letter-spacing:-.68px;margin-right:8px}@media (min-width: 600px){.price-3IdUj{font-size:40px;line-height:56px;letter-spacing:-.8px}}.body-3K9Fv{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:23px}@media (min-width: 600px){.body-3K9Fv{font-size:16px;line-height:23px}}.schedule-3NF9I{align-self:flex-end;margin-bottom:7px}@media (min-width: 600px){.schedule-3NF9I{margin-bottom:9px}}.button-1FUon{width:100%;padding:12px 20px;margin-top:16px;text-align:center;font-size:16px;line-height:21px;letter-spacing:-.32px}@media (min-width: 600px){.button-1FUon{padding:16px 20px;margin-top:56px}}.borderCorrection-1pfY0{padding:11px 20px}@media (min-width: 600px){.borderCorrection-1pfY0{padding:15px 20px}}\n", ""]);
// Exports
exports.locals = {
	"card": "card-7oN3x",
	"discount": "discount-RBIxN",
	"title": "title-393ca",
	"priceContainer": "priceContainer-3O_ME",
	"price": "price-3IdUj",
	"body": "body-3K9Fv",
	"schedule": "schedule-3NF9I",
	"button": "button-1FUon",
	"borderCorrection": "borderCorrection-1pfY0"
};
module.exports = exports;
