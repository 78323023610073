import React from 'react';
import R from 'ramda';
import { oneOf, string, bool } from 'prop-types';
import Link from 'components/Link';
import Analytics from 'instances/analytics';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import {
  getSubscriptionPaymentPageUri,
  getValidProductId,
  supportedSubscriptionUids,
} from 'helpers/paymentPageUrl/getSubscriptionPaymentPageUri';
import classNames from 'classnames';
import CSS from './CallToActionButton.scss';

const StyledButton = styledModule(Button)(CSS.button);

function getPaymentPage(productId) {
  return getSubscriptionPaymentPageUri({
    paymentPath: '/try-now',
    productId,
  });
}

function trackEvent(productId, locationInLayout) {
  Analytics.track('Provider Subscription/Select Subscription Product', {
    internal_location: 'blendle-premium-upsell-screen',
    subscription_product_uid: productId,
    location_in_layout: locationInLayout,
  });
}

function CallToActionButton({
  subscriptionType,
  buttonColor,
  borderCorrection,
  locationInLayout,
  text,
  className,
}) {
  const productId = getValidProductId(subscriptionType);

  return (
    <StyledButton
      component={Link}
      href={getPaymentPage(productId)}
      color={buttonColor}
      onClick={() => trackEvent(productId, locationInLayout)}
      className={classNames(className, borderCorrection ? CSS.borderCorrection : null)}
      data-testid={`upsell-page-call-to-action-${locationInLayout}-${productId}`}
    >
      {text}
    </StyledButton>
  );
}

CallToActionButton.propTypes = {
  subscriptionType: oneOf(supportedSubscriptionUids).isRequired,
  buttonColor: string,
  borderCorrection: bool,
  locationInLayout: string.isRequired,
  text: string,
  className: string,
};

CallToActionButton.defaultProps = {
  buttonColor: 'razzmatazz',
  borderCorrection: false,
  text: 'Neem Premium',
  className: undefined,
};

export default CallToActionButton;
