// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-1v0BH{from{opacity:0}to{opacity:1}}.greeting-3F6s1{margin:32px auto 5px;color:#5b5b5b;font-family:\"GT-Walsheim\",sans-serif;text-align:center;line-height:95%;font-size:16px;letter-spacing:-.32px;opacity:0;animation:fadeIn-1v0BH 0.3s 0.2s ease-out forwards}@media (min-width: 600px){.greeting-3F6s1{margin:43px auto 17px;opacity:1;animation:none;font-size:20px;letter-spacing:-.4px}}.title-2-UU8{margin:0 auto 38px;color:#333;font-family:\"GT-Walsheim\",sans-serif;text-align:center;font-size:32px;line-height:30px;letter-spacing:-1.6px;opacity:0;animation:fadeIn-1v0BH 0.3s 0.2s ease-out forwards}@media (min-width: 600px){.title-2-UU8{margin:0 auto 72px;max-width:659px;opacity:1;animation:none;font-size:60px;line-height:56px;letter-spacing:-3px}}\n", ""]);
// Exports
exports.locals = {
	"greeting": "greeting-3F6s1",
	"fadeIn": "fadeIn-1v0BH",
	"title": "title-2-UU8"
};
module.exports = exports;
