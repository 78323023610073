// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-3whUw{from{opacity:0}to{opacity:1}}.title-15VvS{margin:0 auto 8px;color:#333;font-family:\"GT-Walsheim\",sans-serif;text-align:center;font-size:26px;line-height:24px;letter-spacing:-1.3px;font-weight:600;opacity:0;animation:fadeIn-3whUw 0.3s 0.2s ease-out forwards}@media (min-width: 600px){.title-15VvS{margin:0 auto 13px;max-width:659px;opacity:1;animation:none;font-size:40px;line-height:38px;letter-spacing:-2px}}.subtitle-18RVe{margin:0 auto;color:#5b5b5b;font-family:\"GT-Walsheim\",sans-serif;text-align:center;line-height:21px;font-size:18px;letter-spacing:-.36px;opacity:0;animation:fadeIn-3whUw 0.3s 0.2s ease-out forwards}@media (min-width: 600px){.subtitle-18RVe{margin:0 auto;opacity:1;animation:none;font-size:20px;line-height:19px;letter-spacing:-.4px}}\n", ""]);
// Exports
exports.locals = {
	"title": "title-15VvS",
	"fadeIn": "fadeIn-3whUw",
	"subtitle": "subtitle-18RVe"
};
module.exports = exports;
