import React from 'react';
import styledModule from 'helpers/styledModule';
import SectionHeader from '../SectionHeader';
import Testimonial from '../Testimonial';
import CSS from './Testimonials.scss';

const Container = styledModule.div(CSS.container);
const Wrapper = styledModule.div(CSS.wrapper);
const TestimonialContainer = styledModule.div(CSS.testimonial);

const testimonials = [
  {
    text:
      'Blendle Audio is echt wel een killer feature, de voornaamste reden dat ik nu ook Blendle Premium heb!',
    avatar: '/img/recapture/testimonial_Erwindej1993.jpg',
    name: '@Erwindej1993',
  },
  {
    text:
      'Ik heb opeens een proefmaand Premium. Dus ik luisterde vanochtend Blendle audio en nu die van vanmiddag. Echt heerlijk dit zeg.',
    avatar: '/img/recapture/testimonial_KimRaven.jpg',
    name: '@KimRaven',
  },
  {
    text:
      "Ik heb geen aandelen, maar #blendle is toch wel geweldig. Een verademing in verdieping tegenover het 'snelle' nieuws wat je overal leest",
    avatar: '/img/recapture/testimonial_WeermanRoel.jpg',
    name: '@WeermanRoel',
  },
];

const Testimonials = () => (
  <Container>
    <SectionHeader
      title="Een paar verliefde gebruikers"
      subtitle="We hebben deze mensen uiteraard dik betaald"
    />
    <Wrapper>
      {testimonials.map(testimonial => (
        <TestimonialContainer key={testimonial.name}>
          <Testimonial {...testimonial} />
        </TestimonialContainer>
      ))}
    </Wrapper>
  </Container>
);

export default Testimonials;
