// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1uUN9{position:relative;background:white;overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1uUN9"
};
module.exports = exports;
