// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-27OY_{margin-top:20px}@media (min-width: 600px){.container-27OY_{margin-top:40px}}.wrapper-3jQp8{margin-top:24px}@media (min-width: 600px){.wrapper-3jQp8{margin-top:72px}}.testimonial-Hh5Vv{vertical-align:top;margin-right:0;margin-bottom:29px}.testimonial-Hh5Vv:last-child{margin-bottom:0}@media (min-width: 1064px){.testimonial-Hh5Vv{display:inline-block;margin-right:29px;margin-bottom:0}.testimonial-Hh5Vv:last-child{margin-right:0}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-27OY_",
	"wrapper": "wrapper-3jQp8",
	"testimonial": "testimonial-Hh5Vv"
};
module.exports = exports;
