import React from 'react';
import PropTypes from 'prop-types';
import styledModule from 'helpers/styledModule';
import CSS from './Testimonial.scss';

const Card = styledModule.div(CSS.card);
const Apostrophe = styledModule.h1(CSS.apostrophe);
const Body = styledModule.div(CSS.body);
const Footer = styledModule.div(CSS.footer);
const Avatar = styledModule.img(CSS.avatar);
const Name = styledModule.div(CSS.name);

const Testimonial = ({ text, avatar, name }) => (
  <Card>
    <Apostrophe />
    <Body>{text}</Body>
    <Footer>
      <Avatar src={avatar} />
      <Name>{name}</Name>
    </Footer>
  </Card>
);

Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Testimonial;
