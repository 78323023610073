// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uspTitle-2hQrh{margin:15px 0 5px;color:#333;font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:21px;letter-spacing:-.36px;font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"uspTitle": "uspTitle-2hQrh"
};
module.exports = exports;
