// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3z245{min-height:calc(100vh - 54px);text-align:center;background:white;display:flex;flex-direction:column}@media (min-width: 600px){.container-3z245{min-height:calc(100vh - 66px)}}.giftWrapper-3s-NT{flex-grow:1;display:flex;flex-direction:column;justify-content:center}.content-2xwWB{margin:70px auto}.presentIllustration-1MToU{margin:0 auto;width:110px}@media (min-width: 600px){.presentIllustration-1MToU{width:190px}}.title-J7bgk{font-family:\"GT-Walsheim\",sans-serif;letter-spacing:-0.05em;color:#333;margin-top:14px;font-size:32px}@media (min-width: 600px){.title-J7bgk{font-size:40px}}.subTitle-2C0F3{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;letter-spacing:-0.01em;color:#707070;margin-top:5px;margin-bottom:20px}.cta-2ygvR{width:206px;font-weight:500;margin-top:0}.coupon-33sEL{margin-bottom:70px;padding:0 16px}@media (min-width: 600px){.coupon-33sEL{margin-bottom:20px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3z245",
	"giftWrapper": "giftWrapper-3s-NT",
	"content": "content-2xwWB",
	"presentIllustration": "presentIllustration-1MToU",
	"title": "title-J7bgk",
	"subTitle": "subTitle-2C0F3",
	"cta": "cta-2ygvR",
	"coupon": "coupon-33sEL"
};
module.exports = exports;
