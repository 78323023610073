import React from 'react';
import styledModule from 'helpers/styledModule';
import { PREMIUM_YEARLY_PRODUCT, PREMIUM_MONTHLY_PRODUCT } from 'app-constants';
import Subscription from '../Subscription';
import CSS from './Subscriptions.scss';

const subscriptions = [PREMIUM_YEARLY_PRODUCT, PREMIUM_MONTHLY_PRODUCT];
const Container = styledModule.div(CSS.container);

const Subscriptions = () => (
  <Container>
    {subscriptions.map(subscription => (
      <Subscription key={subscription} subscriptionType={subscription} />
    ))}
  </Container>
);

export default Subscriptions;
