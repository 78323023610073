import React from 'react';
import PropTypes from 'prop-types';
import resetStyles from 'higher-order-components/resetStyles';
import Confetti from 'components/SquareConfetti';
import { PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL } from 'app-constants';
import styledModule from 'helpers/styledModule';
import Link from 'components/Link';
import { convertToLandingProjectURL } from 'libs/middleware/routerRedirectToLanding';
import { translate, translateElement } from 'instances/i18n';
import CSS from './Gift.scss';
import HorizontalAlignment from '../HorizontalAlignment';
import CallToActionButton from '../CallToActionButton';

const Container = styledModule.div(CSS.container);
const GiftWrapper = styledModule.div(CSS.giftWrapper);
const Content = styledModule.div(CSS.content);
const PresentIllustration = styledModule.img(CSS.presentIllustration);
const Title = styledModule.h1(CSS.title);
const SubTitle = styledModule.p(CSS.subTitle);
const Cta = styledModule(CallToActionButton)(CSS.cta);
const Coupon = styledModule(SubTitle)(CSS.coupon);

const imagePath = '/img/illustrations/premium/present_box';

const Gift = ({ firstName }) => (
  <Confetti limit={200}>
    <Container>
      <GiftWrapper>
        <HorizontalAlignment>
          <Content>
            <PresentIllustration
              src={`${imagePath}@2x.png`}
              srcSet={`${imagePath}.png 1x, ${imagePath}@2x.png 2x`}
              alt="Present illustration"
            />
            <Title>
              {translateElement('upsell.gift.title', [
                firstName || translate('upsell.gift.title_name_fallback'),
              ])}
            </Title>
            <SubTitle>{translate('upsell.gift.subtitle')}</SubTitle>
            <Cta
              buttonColor="cash-green"
              text={translate('upsell.gift.cta')}
              locationInLayout="header"
              subscriptionType={PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL}
            />
          </Content>
        </HorizontalAlignment>
      </GiftWrapper>
      <Coupon>
        {translate('upsell.gift.coupon.text')}{' '}
        <Link href={convertToLandingProjectURL('/actie')}>
          {translate('upsell.gift.coupon.cta')}
        </Link>
      </Coupon>
    </Container>
  </Confetti>
);

Gift.propTypes = {
  firstName: PropTypes.string,
};

Gift.defaultProps = {
  firstName: undefined,
};

export default resetStyles(Gift);
