// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-19IZ_{margin:13px auto 70px;display:flex;flex-wrap:wrap;justify-content:center}@media (min-width: 600px){.container-19IZ_{margin:155px auto 70px}}.link-1dowY{padding-right:14px;padding-top:8px;font-family:\"GT-Walsheim\",sans-serif;font-size:14px;line-height:18px;letter-spacing:-.28px;color:#999;text-decoration:none;transition:color 0.1s ease-out}.link-1dowY:hover{color:#ff1060}.link-1dowY:last-child{padding-right:0}@media (min-width: 600px){.link-1dowY{padding-right:23px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-19IZ_",
	"link": "link-1dowY"
};
module.exports = exports;
