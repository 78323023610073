import React from 'react';
import PropTypes from 'prop-types';
import CSS from './Intro.scss';

const SectionHeader = ({ title, subtitle }) => (
  <div>
    <h2 className={CSS.title}>{title}</h2>
    <p className={CSS.subtitle}>{subtitle}</p>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SectionHeader;
