import React from 'react';
import { string } from 'prop-types';
import resetStyles from 'higher-order-components/resetStyles';
import styledModule from 'helpers/styledModule';
import HorizontalAlignment from '../HorizontalAlignment';
import Intro from './Intro';
import Subscriptions from './Subscriptions';
import Benefits from './Benefits';
import WhyPremium from './WhyPremium';
import Testimonials from './Testimonials';
import BottomCallToActionSection from './BottomCallToActionSection';
import Footer from './Footer';
import CSS from './ContinueReading.scss';

const Container = styledModule.div(CSS.container);

const ContinueReading = ({ firstName }) => (
  <Container data-testid="premium-upsell-page">
    <HorizontalAlignment>
      <Intro firstName={firstName} />
      <Subscriptions />
      <Benefits />
      <WhyPremium />
      <Testimonials />
      <BottomCallToActionSection />
      <Footer />
    </HorizontalAlignment>
  </Container>
);

ContinueReading.propTypes = {
  firstName: string,
};

ContinueReading.defaultProps = {
  firstName: undefined,
};

export default resetStyles(ContinueReading);
