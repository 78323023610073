import React from 'react';
import styledModule from 'helpers/styledModule';
import { CircularCheckIcon } from '@blendle/lego';
import CSS from './Benefits.scss';

const Container = styledModule.div(CSS.container);
const BenefitPair = styledModule.div(CSS.benefitPair);
const Benefit = styledModule.div(CSS.benefit);
const Body = styledModule.p(CSS.body);

function Benefits() {
  return (
    <Container>
      <BenefitPair>
        <Benefit>
          <CircularCheckIcon />
          <Body>De beste artikelen</Body>
        </Benefit>
        <Benefit>
          <CircularCheckIcon />
          <Body>Gepersonaliseerd</Body>
        </Benefit>
      </BenefitPair>
      <BenefitPair>
        <Benefit>
          <CircularCheckIcon />
          <Body>Geen advertenties</Body>
        </Benefit>
        <Benefit>
          <CircularCheckIcon />
          <Body>Altijd opzegbaar</Body>
        </Benefit>
      </BenefitPair>
    </Container>
  );
}

export default Benefits;
